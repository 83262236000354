<template>
  <div class="py-1 bg-light d-flex justify-content-end">
    <!-- <p-menubar class="nav nav flex-nowrap" style="width:calc(100vw - 20px); overflow-x: auto;" :model="menus" :key="index">
        <template #item="{item}">
          <router-link :to="item.to" custom v-slot="{href, route, navigate, isActive, isExactActive}">
            <a :href="href" @click="navigate" :class="{'active-link': isActive, 'active-link-exact': isExactActive}">{{route.fullPath}}</a>
          </router-link>
        </template>
      </p-menubar> -->
    <PMenubar
      v-if="isAdmin"
      :model="menus"
      class="btn btn-light nav nav flex-nowrap nav-link dropdown-toggle bg-light w-100 nav-link"
    />
    <ul
      v-else
      class="nav nav flex-nowrap"
      style="width: calc(100vw - 20px); overflow-x: auto"
    >
      <template v-for="(menu, index) in menus">
        <!-- <p-menu v-if="menu.items" :model="menu.items" :key="index">
        <template #item="{item}">
          <router-link :to="item.to" custom v-slot="{href, route, navigate, isActive, isExactActive}">
            <a :href="href" @click="navigate" :class="{'active-link': isActive, 'active-link-exact': isExactActive}">{{route.fullPath}}</a>
          </router-link>
        </template>
      </p-menu> -->
        <li v-if="menu.menus !== undefined" :key="index" class="nav-item">
          <a
            class="btn btn-light nav-link dropdown-toggle mx-1"
            :class="{
              'btn-primary': extractSubmenuPathNames(menu).includes($route.name),
            }"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            :aria-expanded="false"
            >{{ menu.label }}</a
          >

          <ul class="dropdown-menu">
            <li
              @click.prevent="goTo(getMenuRoute(submenu))"
              v-for="(submenu, i) in menu.menus"
              :key="i"
            >
              <!--***** debut submenu****-->

              <!--***** fun submenu****-->
              {{ menu.to }}
              <router-link
                :to="getMenuRoute(submenu)"
                :class="{ 'btn-primary': $route.name == getRouteName(menu.to) }"
                class="dropdown-item"
                ><i class="fa-sm mr-2"></i> {{ submenu.label }}</router-link
              >
            </li>
          </ul>
        </li>
        <li class="nav-item" v-else :key="index">
          <a
            class="btn nav-link mx-1"
            :class="{
              'btn-light': $route.name !== getRouteName(menu.to),
              'btn-primary': $route.name === getRouteName(menu.to),
            }"
            @click.prevent="goTo(getMenuRoute(menu))"
            aria-current="page"
            href="#"
            >{{ menu.label }}</a
          >
        </li>
      </template>
    </ul>
    <div class="d-flex justify-content-center">
      <!-- <a class="btn btn-warning nav-link mx-4" style="min-width: 220px" href="#">Inscrire un PPI</a> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { authMixin } from "../../../mixins/auth-mixin";
export default {
  data() {
    return {};
  },
  mixins: [authMixin],
  computed: {
    ...mapGetters({
      menus: "setting/userMenus",
    }),
    isAdmin() {
      return this.$userRole == "ROLE_ADMIN";
    },
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    getMenuRoute(menu) {
      console.log(menu);
      return menu.to instanceof Object ? menu.to : { name: menu.to };
    },
    getRouteName(to) {
      return to instanceof Object ? to.name : to;
    },
    extractSubmenuPathNames(menu) {
      return menu !== undefined && menu.menus instanceof Array
        ? menu.menus.map((el) => (el.to instanceof Object ? el.to.name : el.to))
        : [];
    },
  },
};
</script>

<style scoped>
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text,
.p-menubar {
  color: black !important;
}
</style>
