<template>
  <!-- Page Content -->
  <div id="page-content-">
    <dash-navbar @toggleSidebar="sidebar = !sidebar">
      <template #notification>
        <v-offline
          online-class="online"
          offline-class="offline"
          @detected-condition="onNetworkChange"
        >
          <template v-if="!online">
            <div
              class="alert alert- py-2 mb-0 d-flex justify-content-end bg-red"
              style="z-index: 1000; background: red; border-radius: 0px"
            >
              <div
                class="flex w-full text-white h-full justify-center items-center text-6xl"
              >
                Problème de connexion internet. Veuillez revérifier votre connexion.... 💩
              </div>
            </div>
          </template>
        </v-offline>
      </template>
    </dash-navbar>
    <!-- <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Data</li>
      </ol>
    </nav> -->
    <div class="container-fluid">
      <dash-sidebar />
      <div class="row">
        <main class="col-md-9 ms-sm-auto col-lg-10">
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
// import DashNavbar from '@/components/partials/Navbar'

import DashNavbar from "./partials/espace/DashNavbar.vue";
import DashSidebar from "./partials/espace/DashSidebar";
import { authMixin } from "../mixins/auth-mixin";
import VOffline from "../components/common/VOffline.vue";

export default {
  components: {
    DashSidebar,
    DashNavbar,
    VOffline,
  },
  mixins: [authMixin],

  data() {
    return {
      sidebar: true,
      online: true,
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          active: true,
        },
      ],
    };
  },
  methods: {
    onNetworkChange(status) {
      this.online = status;
    },
  },
};
</script>
