<
<template>
  <div :class="wrapperClass">
    <slot></slot>
  </div>
</template>
<script>
import Ping from "ping.js";
export default {
  name: "VOffline",
  props: {
    onlineClass: {
      type: String,
      required: false,
      default: "",
    },
    offlineClass: {
      type: String,
      required: false,
      default: "",
    },
    pingUrl: {
      type: String,
      required: false,
      default: "https://google.com",
    },
    checkNetworkTimeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      isOnline: window.navigator.onLine || false,
      events: ["online", "offline", "load"],
      url: this.pingUrl || "https://google.com",
    };
  },
  computed: {
    wrapperClass() {
      if (this.isOnline) {
        return typeof this.onlineClass === "string" ? this.onlineClass : "";
      } else {
        return typeof this.offlineClass === "string" ? this.offlineClass : "";
      }
    },
  },
  async mounted() {
    /**
     * Created lifecycle hook
     */
    this.events.forEach((event) => window.addEventListener(event, this.check));

    setInterval(async () => {
      await this.check();
    }, this.checkNetworkTimeout);
  },
  beforeDestroy() {
    this.events.forEach((event) => window.removeEventListener(event, this.check));
  },
  methods: {
    async check() {
      // Local functions
      /**
       * Pings the URL and emits an
       * detected online/offline event.
       *
       *
       */
      const p = new Ping();
      try {
        const ping = await p.ping(this.url);
        if (ping || window.navigator.onLine) {
          this.isOnline = true;
          this.$emit("detected-condition", this.isOnline);
        }
      } catch (error) {
        if (error || !window.navigator.onLine) {
          this.isOnline = false;
          this.$emit("detected-condition", this.isOnline);
        }
      }
    },
  },
};
</script>
