<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse">
    <div class="position-sticky sidebar-sticky pt-3">
      <ul class="nav flex-column mb-2">
        <div class="p-3 text-center">
          <div class="mb-3 d-flex justify-content-center">
            <a href="#" class="">
              <div
                class="rounded-circle style_bg_img border"
                style="
                  background-image: url('/assets/img/profilMan.jpg');
                  height: 80px;
                  width: 80px;
                "
              ></div>
            </a>
          </div>
          <h5
            v-if="$user.user != null"
            class="text-uppercase text-center text-white text-wrap"
          >
            {{ $user.user.nomPrenom }}
          </h5>
          <h5 v-else class="text-uppercase text-center text-white text-wrap">
            {{ $user.username.split("@").pop() }}
          </h5>
          <small class="text-white-50 text-center">{{ $user.username }}</small>
        </div>
        <hr class="bg-white mx-3" />

        <li class="nav-item" v-for="(menu, i) in menus" :key="i">
          <router-link
            class="nav-link"
            :to="extractMenuTarget(menu)"
            aria-current="page"
            href="#"
          >
            <i class="feather bi-list"></i>
            {{ menu.label }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { authMixin } from "../../../mixins/auth-mixin";
export default {
  data() {
    return {};
  },
  mixins: [authMixin],
  computed: {
    ...mapGetters({
      // menus: "setting/userMenus",
      menus: "setting/userSideMenus",
    }),
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    extractMenuTarget(menu) {
      return menu.to instanceof Object ? menu.to : { name: menu.to };
    },
    extractSubmenuPathNames(menu) {
      return menu !== undefined && menu.menus instanceof Array
        ? menu.menus.map((el) => el.to)
        : [];
    },
  },
};
</script>
