<template>
  <header class="col-md-9 ms-sm-auto col-lg-10">
    <nav
      class="navbar navbar-expand-sm navbar-light bg-white flex-md-nowrap px-0 py-1 shadow-sm"
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img
            src="/assets/img/logo.png"
            loading="lazy"
            width="180"
            class="img-fluid"
            alt="logo"
            id="logo"
          />
        </a>
        <button
          class="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <button
          class="navbar-toggler border-0 d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="bi bi-menu-app"></i>
        </button>
        <div class="text-uppercase d-none d-md-block">
          <span class="badge bg-primary">
            <h6 class="text-nowrap m-0">Espace {{ mxAuthCurrentUserSpaceName }}</h6>
          </span>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle style_dropdown_toggle_no_after"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi-envelope position-relative"></i>
                <!-- <i class="bi-record-fill position-relative text-warning"></i> -->
              </a>
              <ul class="dropdown-menu style_dropdown_menu dropdown-menu-end my-0 p-0">
                <div class="list-group">
                  <!-- <a href="#" class="list-group-item list-group-item-action px-2" aria-current="true">
                    <div class="card-body p-0">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h6>
                        <small class="text-success">11:50</small>
                      </div>
                      <small class="text-muted">Lorem ipsum dolor sit amet</small>
                    </div>
                  </a> -->
                </div>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle style_dropdown_toggle_no_after"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi-bell position-relative"> </i>
                <i class="bi-record-fill position-relative text-warning">
                  {{ notifications.length }}
                </i>
              </a>
              <ul class="dropdown-menu style_dropdown_menu dropdown-menu-end my-0 p-0">
                <div
                  class="list-group"
                  v-for="notification in notifications"
                  :key="notification.id"
                >
                  <!--<a
                    href="#"
                    class="list-group-item list-group-item-action px-2"
                    aria-current="true"
                  >-->
                  <b-button
                    v-b-modal.modal-2
                    class="list-group-item list-group-item-action px-2"
                    @mouseenter="contenu = notification.content"
                  >
                    <div class="card-body p-0">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-0">{{ notification.title }}</h6>
                        <!--<small class="text-success">11:50</small>-->
                      </div>
                      <small class="text-muted">{{ notification.content }}</small>
                    </div>
                  </b-button>
                  <!-- </a>-->
                </div>

                <b-modal id="modal-2" title="Notification">
                  <p class="my-4">{{ contenu }}</p>
                </b-modal>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click.prevent="logout" href="#"
                ><i class="bi-power"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <slot name="notification"></slot>
    <dash-sub-navbar />
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DashSubNavbar from "./DashSubNavbar.vue";
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  components: {
    DashSubNavbar,
  },
  data() {
    return {
      nombreNotification: "",
      contenu: "",
    };
  },
  mixins: [authMixin],
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
      // agent :'agent/agent'
    }),
  },
  created() {
    this.getNotification();
  },
  methods: {
    ...mapActions({
      fetchAgentNotifications: "notification/fetchAgentNotifications",
    }),
    toggleMenu() {
      this.$emit("toggleSidebar");
    },
    getNotification() {
      //console.log("---------------------avant");
      this.fetchAgentNotifications();
      //console.log("---------------------apres");
      // console.log(this.notifications);
      //console.log(not);
    },
  },
};
</script>
